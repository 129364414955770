import { useContext, useEffect, useState, useRef } from "react";
import MenuLateral from "../../../componentes/gerais/Menu";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { Spinner } from "../../../componentes/Spinner";
import { Alert, Button, Box } from "@mui/material";
import { Campo } from "../../../componentes/form/campo";
import useConexaoSocket from "../chat/hook/useConexaoSocket";
import useSalvarSala from "../chat/hook/useSalvarSala";
import useNomeSala from "../chat/hook/useNomeSala";
import APISemNode from "../../../servicos/APISemNode";
import { useLocation, useNavigate, useParams, json } from "react-router-dom";

// Componente principal Chat
export default function Chat() {
    const [loading, setLoading] = useState(true);
    const [mensagens, setMensagens] = useState([]); // Armazena as mensagens
    const [msg, setMsg] = useState("");
    const { dados, websocket } = useContext(ContextoGeral);
    const [menu, setMenu] = useState(false);
    const [pagina, setPagina] = useState(1); // Paginação para buscar mensagens
    const [dadosServico, setDadosServico] = useState(null);
    const [exibirNomeSala, setExibirNomeSala] = useState('Chat');
    const [executarHook, setExecutarHook] = useState(false);

    const location = useLocation();

    const { quemConversar, nomeSala, voltar } = useNomeSala(location, dados);

    const { idSala, msgSala } = useSalvarSala({ ...dados, room: nomeSala, paraQuem: quemConversar, idSolicitante: location?.state?.idSolicitante }, location);
    const { socket, connected, msgConexao } = useConexaoSocket({ ...dados, room: nomeSala, quemConversar: quemConversar }, executarHook);

    // idSala esta vazio por isso não esta buscando as mensgens
    useEffect(() => {
        setTimeout(() => setLoading(false), 2000);

        if (location?.state) {
            setDadosServico(location?.state);
        }

        if (location?.state?.idServico) {
            setExecutarHook(true)
        }
    }, []);

    useEffect(() => {
        if (msgSala) {
            setMsg(msgSala);
        }

        if (msgConexao) {
            setMsg(msgConexao);
        }
    }, [msgSala, msgConexao]);

    useEffect(() => {
        if (socket && connected) {

            // Enviar a mensagem para limpar a contagem de mensagens
            socket.emit("open-chat", { userId: dados?.idProf, nomeSala });

            // Emite o evento 'chat-opened' ao montar o componente
            socket.emit("chat-opened", { userId: dados?.idProf });

            // Emite o evento 'chat-closed' ao desmontar o componente
            return () => {
                socket.emit("chat-closed", { userId: dados?.idProf, idConfig: dados?.idConfig, nomeSala });
            };
        }

    }, [socket, connected, dados.idConfig, dados.idProf, nomeSala]); // Dispara quando o socket estiver conectado


    useEffect(() => {

        if (!socket && !connected) {
            console.log('Aguardando socket e conexão...');
            return; // Não executa o código até que o socket e connected estejam prontos
        }

        if (socket && connected) {

            // Quando o socket receber uma nova mensagem

            socket.on("receive-message", (novaMensagem) => {

                if (novaMensagem?.from == nomeSala) {

                    // Atualiza o estado de mensagens adicionando a nova mensagem recebida
                    setMensagens((prevMensagens) => [
                        ...prevMensagens,
                        {
                            idUsuario: novaMensagem.sendBy, // Identificador do remetente
                            mensagem: novaMensagem.message,   // O texto da mensagem recebida
                            tipo: novaMensagem.sendBy === dados.idProf ? 'enviada' : 'recebida', // Define o tipo
                            nomeUsuario: novaMensagem.nome, // Nome do remetente
                            timeCadastro: new Date().toISOString(), // Hora da mensagem
                        }
                    ]);
                }
            });
        }


        if (location?.state?.idServico) {

            setExibirNomeSala(`Servico: ${location?.state?.idServico}`);
        }

    }, [socket, connected, nomeSala]);

    return (
        <>
            <div style={{
                position: "fixed",
                width: "100%",
                top: 0
            }}>
                <TopoMenu voltar={voltar} titulo="Chat profissional" setShow={setMenu} state={{ idServico: location?.state?.idServico }} />
            </div>
            {loading
                ? <Spinner />
                : <div>
                    {/* Contêiner para exibir a mensagem de erro no topo */}
                    {msg ? (
                        <Box
                            sx={{
                                top: "8vh",
                                left: 0,
                                width: "100%",
                                zIndex: 9999, // Garante que o alerta fique acima de outros elementos
                                position: "relative"
                            }}
                        >
                            <Alert severity="error" style={{ textAlign: "center" }}>{msg}</Alert>
                        </Box>
                    ) : (
                        <>
                            {/* O restante do conteúdo será renderizado apenas se não houver mensagem de erro */}
                            <Box
                                sx={{
                                    backgroundColor: "#100940",
                                    color: "#ffffff",
                                    padding: "10px 20px",
                                    borderTopLeftRadius: "16px",
                                    borderTopRightRadius: "16px",
                                    textAlign: "center",
                                    marginBottom: "16px",
                                    position: "fixed",
                                    width: "100%",
                                    marginTop: "-7.9%",
                                }}
                            >
                                {exibirNomeSala}
                            </Box>
                            <div className="container" style={{ paddingBottom: "91px", marginTop: "19%" }}>
                                {/* Exibe as mensagens enviadas e recebidas */}
                                <Mensagem
                                    idSala={idSala}
                                    setMensagens={setMensagens}
                                    mensagens={mensagens}
                                    pagina={pagina}
                                    setPagina={setPagina}
                                />
                            </div>
                            <BlocoEnviarMsg
                                socket={socket}
                                connected={connected}
                                idSala={idSala}
                                idConfig={dados?.idConfig}
                                setMensagens={setMensagens}
                                mensagens={mensagens}
                                setMsg={setMsg}
                                quemConversar={quemConversar}
                                nomeSala={nomeSala}
                                dadosServico={dadosServico}
                            />
                        </>
                    )}
                </div>}
            <MenuLateral show={menu} setShow={setMenu} ativo={4} />
        </>
    );
}

// Componente Mensagem que busca e exibe as mensagens
function Mensagem({ idSala, setMensagens, mensagens, pagina, setPagina }) {

    const { dados } = useContext(ContextoGeral);
    const mensagensEndRef = useRef(null); // Referência ao final da lista de mensagens
    const mensagensContainerRef = useRef(null); // Referência ao container de mensagens
    const [carregandoComponente, setCarregandoComponente] = useState(true);
    const [novaMensagem, setNovaMensagem] = useState(false); // Estado para controlar a exibição do aviso


    // Função para buscar mensagens do banco de dados
    function buscarMensagens(idSala, pagina) {
        const dominio = `https://${dados?.dominio}`;

        let rota = 'sem/v1/rotas.php/modulo-chat-profissional/buscarMensagensPorSala';
        if (dados?.dominio == 'mototaxionline.srv.br') {
            rota = 'sem/v1/rotas/modulo-chat-profissional/buscarMensagensPorSala';
        }

        APISemNode.requisicaoGET(
            dominio,
            `idSala=${idSala}&pagina=${pagina}&idFuncionario=${dados?.idProf}`,
            rota,
            dados?.token
        )
            .then(response => {
                if (response?.dados?.status) {

                    if (Array.isArray(response.dados.dados.resultados) && response.dados.dados.resultados.length != 0) {
                        const novasMensagens = response?.dados?.dados?.resultados.reverse();
                        setMensagens(prevMensagens => [...novasMensagens, ...prevMensagens]); // Mescla mensagens novas com as anteriores
                    }
                } else {
                    console.error("houve um problema ao buscar mensagens:");
                }
            })
            .catch(error => {
                console.error("Erro ao buscar mensagens:");
            });
    }

    // Função para rolar automaticamente até o final das mensagens
    const scrollToBottom = () => {
        if (mensagensEndRef.current) {
            mensagensEndRef.current.scrollIntoView({ behavior: "smooth" });

            if (novaMensagem) {
                setNovaMensagem(false);
            }
        }
    };

    // Função para detectar quando o usuário está a 200px do topo do container de mensagens
    const handleScroll = () => {
        if (mensagensContainerRef.current) {
            const scrollTop = mensagensContainerRef.current.scrollTop;
            if (scrollTop <= 200) {
                // Incrementa a página e busca mais mensagens quando estiver a 200px do topo
                setPagina(prevPagina => prevPagina + 1);
            }
        }
    };

    // Função para detectar quando o usuário está na parte inferior do container de mensagens
    const handleScrollBaixo = () => {
        if (mensagensContainerRef.current) {
            const scrollTop = mensagensContainerRef.current.scrollTop; // Posição da rolagem
            const scrollHeight = mensagensContainerRef.current.scrollHeight; // Altura total do conteúdo
            const clientHeight = mensagensContainerRef.current.clientHeight; // Altura visível do container

            if (scrollTop + clientHeight >= scrollHeight - 200) { // Verifica se está a 200px do final
                scrollToBottom();
                mostrarAviso = 'N';
            }
        }
    };

    useEffect(() => {
        const container = mensagensContainerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);

            // Cleanup para remover o event listener quando o componente for desmontado
            return () => {
                container.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    // Este useEffect só rola até o fim quando uma nova mensagem é adicionada, e não ao carregar mensagens antigas
    let podeRolarBaixo = "N";
    let mostrarAviso = 'S';
    useEffect(() => {
        mensagens.map((msg, index) => (
            podeRolarBaixo = msg?.rolarParaBaixo == "S" ? "S" : "N"
        ));

        if (carregandoComponente) {
            podeRolarBaixo = 'S'
            mostrarAviso = 'N'
        }

        if (podeRolarBaixo == "S") {

            if (mensagens.length > 13) {
                scrollToBottom(); // Só rola para o final quando uma nova mensagem é adicionada
            }
            setTimeout(() => setCarregandoComponente(false), 2000);
        }

        if (mensagens.length > 13) {
            handleScrollBaixo();
            
            if (mensagens[mensagens.length - 1]?.tipo == 'recebida' && mostrarAviso == 'S') {
                setNovaMensagem(true); // Exibe o aviso de nova mensagem
            }
        }
        


    }, [mensagens]);

    useEffect(() => {

        if (idSala) {

            buscarMensagens(idSala, pagina);
        }
    }, [idSala, pagina]);

    return (
        <div>
            {/* Exibe o aviso de nova mensagem */}
            {novaMensagem && (
                <div style={{
                    position: 'fixed',
                    top: '80%',
                    right: '50%',
                    padding: '10px',
                    backgroundColor: 'rgb(201 188 188)',
                    color: 'black',
                    borderRadius: '5px',
                    zIndex: 1000
                }}
                    onClick={scrollToBottom}
                >
                    Nova mensagem recebida!
                </div>
            )}

            <div ref={mensagensContainerRef} style={{ height: "100%", overflowY: "auto", paddingTop: "6%", maxHeight: "900px" }}>
                {mensagens.forEach((msg) => {
                    msg.tipo = dados?.idProf == msg.idUsuario ? 'enviada' : 'recebida';
                })}

                {mensagens.map((msg, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            justifyContent: msg.tipo === "enviada" ? "flex-end" : "flex-start",
                            marginBottom: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "60%",
                                backgroundColor: msg.tipo === "enviada" ? "#d1e7dd" : "#f8d7da",
                                color: "#000",
                                padding: "10px",
                                borderRadius: "10px",
                                borderTopLeftRadius: msg.tipo === "enviada" ? "10px" : "0",
                                borderTopRightRadius: msg.tipo === "enviada" ? "0" : "10px",
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                            }}
                        >
                            {msg.mensagem}
                        </Box>
                    </Box>
                ))}

                {/* Elemento vazio que será usado para rolar até o fim das mensagens */}
                <div ref={mensagensEndRef} />
            </div>
        </div>
    );
}

// Componente de envio de mensagem
function BlocoEnviarMsg({ socket, connected, idSala, idConfig, setMensagens, mensagens, setMsg, quemConversar, nomeSala, dadosServico }) {
    const { dados } = useContext(ContextoGeral);
    const [mensagem, setMensagem] = useState("");

    const idProf = dados?.idProf;
    const nome = dados?.nome;
    const token = dados?.token;
    const tipoUsuario = "P";

    function salvarMensagem(idSala, idProf, tipoUsuario, message, nomeUsuario, tokenAcesso) {
        let dominio = `https://${dados.dominio}`;

        let uri = "sem/v1/rotas.php/modulo-chat-profissional/inserirMensagem";

        if (dados.dominio == 'mototaxionline.srv.br') {
            uri = "sem/v1/rotas/modulo-chat-profissional/inserirMensagem";
        }

        const dadosSalvar = {
            idSala,
            idUsuario: idProf,
            tipoUsuario,
            mensagem: message,
            nomeUsuario
        };

        APISemNode.requisicaoPOST(`${dominio}`, dadosSalvar, `${uri}`, tokenAcesso)
            .then((res) => {
                console.log(res);
                
                if (res?.status == 200) {
                    setMsg("");
                } else {
                    setMsg('Não foi possível salvar a mensagem.');
                }
            })
            .catch((error) => {
                setMsg('Houve um erro ao salvar a mensagem.');
            });
    }

    async function handleSendMessage() {
        if (mensagem.trim() === "") return;

        const dominio = `https://${dados?.dominio}`;

        let validarServico = true;

        if (dadosServico['idServico'] > 0) {

            let rota = 'sem/v1/rotas.php/modulo-chat-profissional/validarStatusServico';
            if (dados?.dominio == 'mototaxionline.srv.br') {
                rota = 'sem/v1/rotas/modulo-chat-profissional/validarStatusServico';
            }

            await APISemNode.requisicaoGET(
                dominio,
                `status=${dadosServico['status']}&idServico=${dadosServico['idServico']}&idCliente=${dadosServico['idSolicitante']}&idCentroCusto=${dadosServico['idCentroCusto']}`,
                rota,
                dados?.token
            )
                .then(response => {

                    console.log(response);

                    if (response?.dados?.status) {

                        validarServico = response?.dados?.dados > 0

                    } else {
                        validarServico = false
                        console.error("houve um problema ao buscar mensagens:");
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar mensagens:");
                });

        }

        if (validarServico) {

            const novaMensagem = {
                idUsuario: idProf, // Adiciona o id do usuário atual para diferenciar entre enviada e recebida
                mensagem: mensagem, // O texto da mensagem
                tipo: "enviada", // Define como "enviada" para o usuário atual
                nomeUsuario: nome, // Nome do usuário que enviou
                timeCadastro: new Date().toISOString(), // Timestamp da mensagem
                rolarParaBaixo: "S"
            };

            let targetUserId = null;

            if (quemConversar == 'central') {
                targetUserId = 'central';
            }

            // Adiciona a nova mensagem ao estado global
            setMensagens([...mensagens, novaMensagem]);

            // Salva a mensagem no banco de dados
            salvarMensagem(idSala, idProf, tipoUsuario, mensagem, nome, token);

            // Emite a mensagem pelo socket, se estiver conectado
            if (socket && connected) {

                socket.emit("send-message", {
                    idConfig: dados?.idConfig,
                    targetUserId: targetUserId,
                    userId: dados?.idProf,
                    message: mensagem,
                    target: quemConversar,
                    nomeSala: nomeSala
                });
            }

            setMensagem(""); // Limpa o campo de entrada
        } else {
            alert('Serviço encerrado')
        }
    };

    return (
        <div>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    backgroundColor: "#f0f0f0",
                    borderTop: "1px solid #ddd",
                }}
            >
                <div style={{ width: "100%" }}>
                    <Campo
                        label="Digite sua mensagem"
                        value={mensagem}
                        onChange={(e) => setMensagem(e.target.value)}
                        style={{ flex: 1, marginRight: "10px" }}
                    />
                </div>
                <Button variant="contained" color="primary" style={{ padding: "14px 16px" }} onClick={handleSendMessage}>
                    Enviar
                </Button>
            </Box>
        </div>
    );
}