import { useContext, useEffect } from "react"
import { ContextoGeral } from "../../contextos/ContextoGeral"
import { useParams } from "react-router-dom"
import AppAPI from "../../servicos/AppAPI"
import { reduceImage, resizePic } from "../../servicos/Uteis"
import ServicoAPI from "../../servicos/ServicoAPI"
import heic2any from "heic2any"

export default function useCamera(onFinish, onError) {
	const { dados } = useContext(ContextoGeral)
	const { id } = useParams()

	function callError(msg) {
		if (window.AndroidInterface)
			window.AndroidInterface.showAndroidVersion(msg)
		onError(msg)
	}

	useEffect(() => {
		window.setImage = async img => {
			AppAPI.inserirLog(dados, {
				msg: "Android enviou imagem com sucesso! Começou a aguardar carregamento da imagem base64 na webview.",
				menu: "Análise Protocolo",
				idProf: dados.idProf,
				idServico: id,
				texto: img ?? null
			})

			function b64ToBlog(base64) {
				try {
					let code = base64.split(";base64,")[1]
					const bytes = atob(code)
					const uint8Array = new Uint8Array(bytes.length)
					for (let i = 0; i < bytes.length; i++)
						uint8Array[i] = bytes.charCodeAt(i)

					return new Blob([uint8Array], { type: "image/heic" })
				} catch {
					print("falha")
					return null
				}
			}

			if (img) {
				if (/data:image\/[bmp,gif,heic,ico,jpg,png,svg,webp,x\-icon,svg+xml]+;base64,[a-zA-Z0-9,+,/]+={0,2}/gm.test(img)) {
					const imageElement = new Image()
					imageElement.onload = async () => {
						AppAPI.inserirLog(dados, {
							msg: "Imagem base64 carregada, agora será processada.",
							menu: "Análise Protocolo",
							idProf: dados.idProf,
							idServico: id,
							texto: img
						})

						print("aguarde o envio da imagem.")
						const base = await reduceImage(imageElement)
						if (base) {
							const resp = await ServicoAPI.salvarImagemCDN(dados, base)

							AppAPI.inserirLog(dados, {
								msg: "Mensagem da API recebida",
								menu: "Análise Protocolo",
								idProf: dados.idProf,
								idServico: id,
								texto: JSON.stringify(resp)
							})

							if (resp?.resultado)
								onFinish(resp.dados)
							else if (resp?.mensagem)
								onError(resp.mensagem)
							else
								onError("Não foi possível se conectar ao CDN")
						} else callError("Imagem não foi reduzida com sucesso")
					}
					imageElement.onerror = async (e) => {
						console.error(JSON.stringify(e, ["message", "arguments", "type", "name"], 3))
						AppAPI.inserirLog(dados, {
							msg: "Falha ao carregar imagem na Webview!",
							menu: "Análise Protocolo",
							idProf: dados.idProf,
							idServico: id,
							texto: e ?? "Não foi retornado um erro para esta interface."
						})

						print("Imagem sendo convertida, aguarde...")
						const blob = b64ToBlog(img)
						heic2any({ blob, toType: "image/jpeg", quality: 0.5 }).then(result => {
							if (result instanceof Blob) {
								const reader = new FileReader()
								reader.addEventListener("load", () => {
									if (reader.result) {

										const imageElement = new Image()
										imageElement.onload = async () => {
											AppAPI.inserirLog(dados, {
												msg: "Imagem base64 reprocessada, tentando converter possível imagem HEIC em JPG.",
												menu: "Análise Protocolo",
												idProf: dados.idProf,
												idServico: id,
												texto: reader.result
											})

											const base = await reduceImage(imageElement)
											if (base) {
												const resp = await ServicoAPI.salvarImagemCDN(dados, base)

												AppAPI.inserirLog(dados, {
													msg: "Mensagem da API recebida",
													menu: "Análise Protocolo",
													idProf: dados.idProf,
													idServico: id,
													texto: JSON.stringify(resp)
												})

												if (resp?.resultado)
													onFinish(resp.dados)
												else if (resp?.mensagem)
													onError(resp.mensagem)
												else
													onError("Não foi possível se conectar ao CDN")
											} else callError("Imagem não foi reduzida com sucesso")
										}

										imageElement.onerror = async (e) => {
											console.error(JSON.stringify(e, ["message", "arguments", "type", "name"], 3))
											AppAPI.inserirLog(dados, {
												msg: "Falha ao carregar imagem na Webview!",
												menu: "Análise Protocolo",
												idProf: dados.idProf,
												idServico: id,
												texto: e ?? "Não foi retornado um erro para esta interface."
											})
											callError("Imagem incompatível, favor envie um tipo de imagem válido (jpg/png) e não foi possível alterar pelo sistema, faça a conversão manualmente obrigado.")
										}
										imageElement.src = reader.result
									} else callError("Imagem incompatível, envie uma imagem válida (jpg/png), a imagem enviada não pôde ser convertida.")
								})

								reader.readAsDataURL(result)
							} else callError("Imagem incompatível, favor envie um tipo de imagem válido (jpg/png) e não pudemos converter.")
						}).catch(r => {
							callError("Imagem incompatível, favor envie um tipo de imagem válido (jpg/png).")
						})
					}
					imageElement.src = img
				} else callError("Imagem incompatível, favor envie um tipo de imagem válido (jpg/png), formato não reconhecido.")
			} else {
				callError("Falha ao recuperar imagem do sistema")
			}
		}

		window.cancelProtocol = () => callError("Operação cancelada pelo usuário")

		try {
			window.AndroidInterface.fotoProtocolo()
		} catch (err) {
			callError(err)
		}
	}, [])
}