import ServicoAPI from "../../../servicos/ServicoAPI"

import { Alert, Backdrop, Button, Card, CircularProgress, Collapse, IconButton, Popover, Typography } from "@mui/material"
import { Component, useContext, useRef, useState } from "react"
import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Campo, Container, Label, Select } from "../../../componentes/form/campo"
import CardEndereco from "../../../componentes/form/CardEndereco"

import { motion } from "framer-motion"
import { Spinner } from "../../../componentes/Spinner"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import Badge from "../../../componentes/uteis/Badge"
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import { configAnim } from "../../../servicos/config/configAnim"
import CheckPermission from "../../../servicos/config/CheckPermission.js"
import { MdContentCopy, MdDraw, MdInfo, MdPhoto, MdQrCode, MdQrCode2, MdSend } from "react-icons/md"
import { BootClickAlert, FakeGpsAlert } from "../../../componentes/gerais/Dialog"
import { copy, print, reduceImage, verificarBootClick } from "../../../servicos/Uteis"
import Protocolo from "./Protocolo"
import { CameraAlt, Close, Collections, Undo } from "@mui/icons-material"
import { baseURL } from "../../../servicos/API"
import axios from "axios"
import Camera from "../../../componentes/gerais/Camera.jsx"
import useCamera from "../../../hooks/general/useCamera.jsx"
import useGallery from "../../../hooks/general/useGallery.jsx"
import AppAPI from "../../../servicos/AppAPI.js"

export default function ServicoFinalizar() {
	const navegar = useNavigate()

	const [debug, setDebug] = useState(<></>)
	const [modalProtocolo, openModalProtocolo] = useState(false)

	const [carregando, setCarregando] = useState(true)
	const [resultado, setResultado] = useState("")
	const [resposta, setResposta] = useState("")
	const [ifoodURL, setIfoodURL] = useState("")

	const [camera, openCamera] = useState(false)

	const [BD, setBD] = useState(false)

	const [data, setData] = useState({})

	const [nome, setNome] = useState('')
	const [motivo, setMotivo] = useState('')
	const [imgFile, setImgFile] = useState("")
	const [confMotivo, setConfMotivo] = useState({
		show: false,
		enabled: false,
		msg: ""
	})

	const [idEndereco, setIdEndereco] = useState(null)

	const [disabledBtnFinalizado, setDisabledBtnFinalizado] = useState(false)

	const [motivoAtributo, setMotivoAtributo] = useState('')
	const [tipoVeiculo, setTipoVeiculo] = useState('')

	const { id } = useParams()

	const { state } = useLocation()

	const { dados } = useContext(ContextoGeral)

	const [protocoloLoading, setProtocoloLoading] = useState(false)

	const [exibirFakeGPS, setExibirFakeGPS] = useState(false)
	const [exibirBootClick, setExibirBootClick] = useState(false)

	const showBtnFinalizar = () => {
		if (data) {
			if (data?.codigoFinalizar)
				return data?.codigoFinalizar?.exibirBotao

			if (data?.codigoFinalizarIfood)
				return data?.codigoFinalizarIfood?.exibirBotao
			return true
		}
		return false
	}

	const handleNome = (event) => {
		setNome(event.target.value)
		localStorage.setItem("nomeFinalizar", event.target.value)
	}

	const handleMotivo = event => {
		setConfMotivo(prev => ({
			...prev,
			enabled: data.motivos.opcoes[event.target.selectedIndex - 1].confirmacao,
			msg: data.motivos.opcoes[event.target.selectedIndex - 1].msg
		}))
		setMotivo(event.target.value)
		setMotivoAtributo(event.target[event.target.selectedIndex].getAttribute('atributo'))
	}

	const dominioNewFoto = [
		// "mototaxionline.srv.br",
		// "logmapp.com.br",
		// "efatagynexpress.com.br",
		// "sidlog.com.br",
		// "kolletaexpress.com.br"
	].includes(dados.dominio)

	const vDominio = (window.AndroidInterface?.recoverVersion() >= 102.23) && dominioNewFoto

	async function finalizar() {
		setResposta(<Col className="mt-4">
			<Spinner />
		</Col>)

		try {
			setDisabledBtnFinalizado(true)

			let statusPermissao = true

			if (statusPermissao && !CheckPermission.checkLocationAvailable()) {
				statusPermissao = false

				if (typeof window.AndroidInterface !== 'undefined')
					window.AndroidInterface.showPermissaoLocalizacao(MensagemApp.msgPermissaoGPS)
			}

			if (statusPermissao && !CheckPermission.checkLocationEnabled()) {
				statusPermissao = false

				window.AndroidInterface?.showHabilitarLocalizacao(MensagemApp.msgHabilitarGPS)
			}

			if (statusPermissao && !CheckPermission.checkStorage()) {
				statusPermissao = false
				window.AndroidInterface?.showPermissaoArmazenamento(MensagemApp.msgPermissaoArmazenamento)
			}

			if (statusPermissao) {
				let location = {
					latitude: 0,
					longitude: 0
				}

				if (window.AndroidInterface) location = JSON.parse(await window.AndroidInterface.getLocation())

				if (window.isLocalHost)
					location = { latitude: -21, longitude: -44 }

				if ((location == '') || (location.latitude == 0 || location.longitude == 0))
					print("Localização indisponível, favor ative a localização para prosseguir.")
				else {
					let dadosServico = {}
					if (motivo != '' && motivoAtributo != '') {
						dadosServico = {
							idServico: id,
							idEndereco: state.idEndereco,
							la: location.latitude,
							lo: location.longitude,
							observacao: nome,
							motivo: {
								label: motivo,
								value: motivoAtributo
							}
						}
					} else {
						dadosServico = {
							idServico: id,
							idEndereco: state.idEndereco,
							la: location.latitude,
							lo: location.longitude,
							observacao: nome,
						}
					}

					let retorno = await ServicoAPI.finalizarEndereco(dados, dadosServico)

					if (retorno?.resultado) {
						if (retorno?.resultado) {
							if (window.AndroidInterface)
								window.AndroidInterface.showAndroidVersion(retorno.mensagem)

							setResposta(
								<Alert severity="success">
									{retorno?.mensagem}
								</Alert>
							)

							if (retorno.dados?.qtdeEndRestantes && retorno.dados?.qtdeEndRestantes > 0) {
								navegar(`/app/servicos/${id}`, { state: {} })
							} else {
								navegar(`/app/servicos`, { state: {} })
							}

						} else if (retorno?.codigo == 400 || retorno?.codigo == 404) {

							let msg = MensagemApp.msgErroApi
							let msgArray = []

							if (retorno?.dados.erros) {
								msg = MensagemApp.msgErroLista
								retorno.dados.erros.forEach(element => {
									msgArray.push(<li>{element.mensagem}</li>)
								})
							} else if (retorno?.mensagem) {
								msg = retorno.mensagem
							}

							setResposta(
								<Alert severity="error">
									{msg}
									{msgArray}
								</Alert>
							)
							setDisabledBtnFinalizado(false)
						} else {
							if (window.AndroidInterface)
								window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)

							setResposta(<Alert severity="error">
								{retorno?.mensagem ?? MensagemApp.msgErroApi}
							</Alert>)
							setDisabledBtnFinalizado(false)
						}
					} else {
						if (!!window.AndroidInterface)
							window.AndroidInterface.showAndroidVersion(retorno?.mensagem ?? MensagemApp.msgErroApi)

						setResposta(<Alert severity="error">
							{retorno?.mensagem ?? MensagemApp.msgErroApi}
						</Alert>)

						setDisabledBtnFinalizado(false)
					}
				}
			} else {
				setResposta(<Alert severity="error">
					{MensagemApp.msgErroLaLo}
				</Alert>)
				setDisabledBtnFinalizado(false)
			}

		} catch (err) {
			console.error(err)
		}
	}


	async function coletarArquivo(event, tipoColeta, rota, dadosAdicionais = {}) {
		if (tipoColeta == 'A') {
			let json = {
				...state,
				...dadosAdicionais,
				campoNome: nome,
				campoMotivo: motivo,
				campoMotivoAtributo: motivoAtributo
			}

			navegar(`/app/servicos/${id}/assinar`, { state: json })
		} else {
			event.target.blur()

			if (CheckPermission.checkCamera()) {
				setBD(true)

				console.log("criando a função")
				if (window.AndroidInterface) {
					window.finalizouEnvio = function (res) {
						console.log("aqui?")
						// try {
						// 	setBD(false)
						// 	if (res != "") {
						// 		let retorno = JSON.parse(res)

						// 		if (retorno?.resultado) {
						// 			setResposta(<Alert severity="success" className="text-center">
						// 				{retorno.mensagem}
						// 			</Alert>)
						// 			setCarregando(true)
						// 		} else {
						// 			if (retorno?.mensagem) {
						// 				window.AndroidInterface.showAndroidVersion(retorno.mensagem)

						// 				setResposta(<Alert severity="error" className="text-center">
						// 					{retorno.mensagem}
						// 				</Alert>)
						// 			} else {
						// 				window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)

						// 				setResposta(<Alert severity="error" className="text-center">
						// 					{MensagemApp.msgErroApi}
						// 				</Alert>)
						// 			}

						// 		}
						// 	} else {
						// 		window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroSistemaCamera)

						// 		setResposta(
						// 			<Alert severity="error" className="text-center">
						// 				{MensagemApp.msgErroSistemaCamera}
						// 			</Alert>
						// 		)
						// 	}
						// 	setProtocoloLoading(false)

						// } catch (err) {
						// 	setProtocoloLoading(false)
						// 	setResposta(<Alert severity="error" className="text-center">
						// 		Erro ao receber imagem.
						// 	</Alert>)
						// }
					}
					setResposta('')
					window.AndroidInterface.ativaCamera('DC', parseInt(dados.idProf), parseInt(id), parseInt(state.idEndereco), tipoColeta, '', rota, dados.token)
				} else {
					setTimeout(() => {
						print('tela de teste, favor testar em um celular android!')
						setBD(false)
					}, 2000)
					setProtocoloLoading(false)
				}
			} else {
				setProtocoloLoading(false)

				if (window.AndroidInterface)
					window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera)
			}
		}
	}

	async function buscarDadosFinalizacao(motivoProvisorio = "") {
		let location = { latitude: 0, longitude: 0 }
		if (window.AndroidInterface != undefined)
			location = JSON.parse(await window.AndroidInterface.getLocation())

		if (window.isLocalHost)
			location = { latitude: -21, longitude: -43 }

		if (location.latitude != 0 && location.longitude != 0) {
			let retorno = await ServicoAPI.statusFinalizar(dados, id, state.idEndereco, state.tipoVeiculo, location)

			console.log(retorno)

			if (retorno.status === 200) {
				if (retorno.data.aux && retorno.data.aux == "finalizado") {
					navegar(`/app/servicos/${id}`)
					if (window.AndroidInterface)
						window.AndroidInterface.showAndroidVersion("Este ponto já foi finalizado")
					else
						print("Este ponto já foi finalizado")
					return
				}

				const dataReturn = retorno.data.dados
				setData(dataReturn)

				if (dataReturn?.motivos?.opcoes && motivoProvisorio != "")
					dataReturn.motivos.opcoes.map((item, i) => {
						if (item.label == motivoProvisorio)
							setConfMotivo(prev => ({
								...prev,
								enabled: dataReturn.motivos.opcoes[i].confirmacao,
								msg: dataReturn.motivos.opcoes[i].msg
							}))
					})
				// if (!dataReturn?.observacao && !dataReturn?.motivos && !dataReturn?.btnAssinatura && !dataReturn?.btnProtocolo && !dataReturn?.btnScanner && !dataReturn?.linkConfirmacao)
				// 	finalizar()
				setResultado(true)
			} else {
				console.log(retorno.msg ?? "")
			}
		}
		setCarregando(false)
	}

	async function verificarFinalizar() {
		let bloqueio = false

		try {
			if (window.AndroidInterface?.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					bloqueio = dados?.configFakeGPS?.opcao == "B"
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					if (!bloqueio)
						bloqueio = dados?.configBootClick?.opcao == "B"
				}
			}
		} catch {
			console.log(MensagemApp.funcIndisponivel)
		}

		if (bloqueio) return

		if (confMotivo.enabled) setConfMotivo(prev => ({ ...prev, show: true }))
		else finalizar()
	}

	useEffect(() => {
		window.cancelProtocol = async () => {
			setProtocoloLoading(false)
		}

		if (state) {
			if (state.tipoVeiculo)
				setTipoVeiculo(state.tipoVeiculo)
			if (state.idEndereco)
				setIdEndereco(state.idEndereco)
		}

		if (carregando) {
			async function buscarDados() {
				buscarDadosFinalizacao(state?.campoMotivo ?? "")
			}

			if (state?.campoNome)
				setNome(state.campoNome)

			if (state?.campoMotivo) {
				setMotivo(state?.campoMotivo ?? "")
				setMotivoAtributo(state?.campoMotivoAtributo ?? "")
			}

			buscarDados()
		}
	}, [carregando])

	return <>
		<motion.div
			style={{ backgroundColor: '#eee', height: '100vh', width: '100vw', padding: '1rem' }}
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<>
				{imgFile && <img className="w-100" src={imgFile} />}
				{carregando
					? <Spinner />
					: <>
						{resultado
							? <>
								<Row>
									<Col>
										<div
											className="position-absolute"
											style={{ right: "1rem", zIndex: 500 }}
										>
											<Link to={`/app/servicos/${id}`} style={{ width: 'auto' }}>
												<button className='btn-close' />
											</Link>
										</div>
										<div style={{ marginTop: "2rem" }}>
											<CardEndereco
												id={state?.idAtivo}
												endereco={state?.endereco}
												icone
												observacao={state?.observacao}
											/>
										</div>

										<Collapse in={ifoodURL == ""}>
											{data?.codigoFinalizarIfood && <EstruturaCodigo
												label={data?.codigoFinalizarIfood?.labelInput}
												info={data?.codigoFinalizarIfood?.msgInfo}
												metodo="POST"
												uri="/verificaCodigoEntrega"
												idEndereco={state.idEndereco}
												reload={() => {
													setCarregando(true)
												}}
											/>}

											{data?.codigoFinalizar && <EstruturaCodigo
												label={data?.codigoFinalizar?.labelInput}
												info={data?.codigoFinalizar?.msgInfo}
												metodo="PUT"
												uri="/informarCodigoFinalizarEnd"
												idEndereco={state.idEndereco}
												reload={() => {
													setCarregando(true)
												}}
											/>}

											<div>
												{data?.observacao && <>
													<label>
														{
															data.observacao.titulo
																? data.observacao.titulo
																: "Observação"
														}
													</label>
													<Campo
														label={data.observacao.label}
														value={nome}
														obrigatorio={data.observacao?.obrigatorio}
														onChange={handleNome}
													/>
												</>}

												{data?.motivos !== undefined && <div className="mt-3">
													<Container>
														<Select
															id="motivo"
															className="select"
															onChange={handleMotivo}
															value={motivo}
														>
															<option value="" disabled>selecione...</option>
															{data.motivos.opcoes.map((option, i) => {
																return (<option
																	key={option.label}
																	value={option.label}
																	atributo={option.value}
																>
																	{option.label}
																</option>)
															})}
														</Select>
														<Label htmlFor="motivo">
															Selecione um motivo
															{data.motivos?.obrigatorio ? (
																<span className="red">✶</span>
															) : ''}
														</Label>
													</Container>
												</div>}
											</div>

											<Row className="d-flex justify-content-between g-0 mt-3" style={{ gap: '0.5rem' }}>
												{data?.btnAssinatura ? (
													<Col>
														<Row className="g-0">
															<button
																onClick={(ev) => { coletarArquivo(ev, 'A', '', data.btnAssinatura.infoAdicional) }}
																className="position-relative d-flex align-items-center justify-content-center gap-1"
																disabled={data.btnAssinatura?.qtdeMax && data.btnAssinatura?.qtdeEnviada >= data.btnAssinatura?.qtdeMax}
															>
																<MdDraw size={18} />
																<small>
																	{data.btnAssinatura?.label ?? ""}
																</small>
																{data.btnAssinatura?.obrigatorio ? (
																	<span className="red">*</span>
																) : ('')}
																{data.btnAssinatura?.qtdeEnviada > 0 && <Badge
																	right="5px"
																	top="-8px"
																	color="green"
																	valor={data.btnAssinatura.qtdeEnviada}
																/>}
															</button>
														</Row>
													</Col>
												) : ('')}

												{data?.btnProtocolo && <Col>
													<Row className="g-0 position-relative">
														<button
															className="position-relative d-flex align-items-center justify-content-center gap-1"
															onClick={e => {
																if (!vDominio) {
																	setProtocoloLoading(true)
																	try {
																		if (window.AndroidInterface?.recoverVersion() > 102.00)
																			openModalProtocolo(true)
																		else
																			coletarArquivo(e, 'P', 'salvarDadosFinalizar')
																	} catch (err) {
																		const msg = "Erro ao iniciar camera atualize seu aplicativo!"
																		try {
																			window.AndroidInterface.showAndroidVersion(msg)
																		} catch {
																			print(msg)
																		}
																		setProtocoloLoading(false)
																	}
																}
															}}
															disabled={data.btnProtocolo?.qtdeEnviada >= data.btnProtocolo?.qtdeMax || protocoloLoading}
														>
															{vDominio && <div className="position-absolute w-100 h-100">
																{!((data.btnProtocolo?.qtdeMax
																	? data.btnProtocolo?.qtdeEnviada >= data.btnProtocolo?.qtdeMax
																	: false) || protocoloLoading) && <Camera
																		setState={setProtocoloLoading}
																		func={async (base) => {
																			await axios.post(baseURL + '/appProfissional/acessar', {
																				dominio: dados.dominio ?? 'mototaxionline.srv.br',
																				rota: '/salvarDadosFinalizar',
																				metodo: "POST",
																				cabecalho: {
																					token: dados.token
																				},
																				data: {
																					idEndereco: idEndereco,
																					idProf: dados.idProf,
																					idServico: id,
																					identificador: "P",
																					imagem: base,
																					length: base.length
																				}
																			}, {
																				timeout: 30000
																			}).then(res => res.data).then(res => {
																				if (res.resultado) {
																					try {
																						window.AndroidInterface.showAndroidVersion(res.mensagem)
																					} catch {
																						alert(res.mensagem)
																					}
																				} else {
																					try {
																						window.AndroidInterface.showAndroidVersion(res.mensagem)
																					} catch {
																						alert(res.mensagem)
																					}
																				}
																			}).catch(rej => rej).finally(() => {
																				setProtocoloLoading(false)
																				setCarregando(true)
																			})
																		}}
																	/>}
															</div>}
															<MdPhoto size={18} />
															<small>
																{protocoloLoading
																	? <CircularProgress size={18} />
																	: <>
																		{data.btnProtocolo?.label ?? ""}
																		{data.btnProtocolo?.obrigatorio && <span className="red">*</span>}
																	</>}
															</small>
															{data.btnProtocolo?.qtdeEnviada > 0 ? (
																<Badge
																	right="5px"
																	top="-8px"
																	color="green"
																	valor={data.btnProtocolo.qtdeEnviada}
																/>
															) : ('')}
														</button>
													</Row>
												</Col>}
												{data?.btnScanner ? (
													<Col>
														<Row className="g-0">
															<button
																onClick={(ev) => { coletarArquivo(ev, 'S', 'salvarDadosFinalizar') }}
																className="position-relative d-flex align-items-center justify-content-center gap-1"
																disabled={
																	data.btnScanner?.qtdeMax
																		? data.btnScanner?.qtdeEnviada >= data.btnScanner?.qtdeMax
																		: false
																}
															>
																<MdQrCode size={18} />
																<small>
																	{data.btnScanner?.label}
																	{data.btnScanner?.obrigatorio && <span className="red">*</span>}
																</small>
																{data.btnScanner?.qtdeEnviada > 0 ? (
																	<Badge
																		right="5px"
																		top="-8px"
																		color="green"
																		valor={data.btnScanner.qtdeEnviada}
																	/>
																) : ('')}
															</button>
														</Row>
													</Col>
												) : ('')}
											</Row>
											{showBtnFinalizar()
												? <Row className='g-0 mt-3'>
													<button disabled={disabledBtnFinalizado} onClick={verificarFinalizar}>Finalizar</button>
												</Row>
												: data?.codigoFinalizar?.msgBotao
													? <div className="alert alert-info text-center mt-3">
														{data?.codigoFinalizar?.msgBotao}
													</div>
													: data?.codigoFinalizarIfood?.msgBotao && <div className="alert alert-info text-center mt-3">
														{data?.codigoFinalizarIfood?.msgBotao}
													</div>}
											{!data && <div className="alert alert-danger text-center mt-3">
												Nenhuma informação de finalização disponível.
											</div>}
										</Collapse>
									</Col>
								</Row>
							</>
							: <div className="alert alert-danger text-center">
								Erro ao buscar informações, localização indisponível.
								<Button onClick={() => navegar("/app/servicos")} fullWidth>Voltar</Button>
							</div>}

						<FakeGpsAlert
							open={exibirFakeGPS}
							setOpen={setExibirFakeGPS}
							opcao={dados?.configFakeGPS?.opcao}
							onClose={() => {
								if (!exibirBootClick && !exibirFakeGPS) finalizar()
							}}
						/>

						<BootClickAlert
							open={exibirBootClick}
							setOpen={setExibirBootClick}
							opcao={dados?.configBootClick?.opcao}
							onClose={() => {
								if (!exibirBootClick && !exibirFakeGPS) finalizar()
							}}
						/>

						<Row className="mt-3">
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								{resposta}
							</motion.div>
						</Row>
						<Backdrop
							open={BD}
						>
							<div style={{ height: '100%' }}>
								<Spinner />
							</div>
						</Backdrop>
					</>}

				<ModalIfood url={ifoodURL} back={() => {
					setCarregando(true)
					setIfoodURL("")
				}} state={state} />
			</>
		</motion.div >
		<Backdrop in={confMotivo.show}>
			<Card className="m-3 p-3 position-relative">
				<IconButton className="position-absolute end-0 top-0" onClick={() => {
					setConfMotivo(prev => ({ ...prev, show: false }))
				}}>
					<Close />
				</IconButton>
				<div className="mt-3 text-center lh-1">
					{confMotivo.msg}
				</div>
				<div className="d-flex gap-3 mt-3">
					<Button
						variant="contained"
						color="success"
						onClick={() => {
							setConfMotivo(prev => ({
								...prev,
								show: false
							}))
							finalizar()
						}}
						fullWidth
					>
						Sim
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							setConfMotivo(prev => ({
								...prev,
								show: false
							}))
						}}
						fullWidth
					>
						Não
					</Button>
				</div>
			</Card>
		</ Backdrop>
		<Backdrop in={modalProtocolo}>
			<BoundaryProtocol>
				<ProtocolContainer
					opcao={data?.btnProtocolo?.opcaoTipoFoto}
					handleClose={() => {
						openModalProtocolo(false)
						setProtocoloLoading(false)
					}}
					reload={() => {
						openCamera(false)
						setCarregando(true)
						openModalProtocolo(false)
						setProtocoloLoading(false)
					}}
					onObsolete={() => {
						try {
							openModalProtocolo(false)
							openCamera(true)
						} catch (err) {
							try {
								window.AndroidInterface.showAndroidVersion("Não foi possível acionar a câmera do dispositivo")
							} catch {
								alert("Não foi possível acionar a câmera do dispositivo")
							}
						}
					}}
					handleDB={async (URL) => {
						const resp = await ServicoAPI.salvarFotoProtocolo(dados, {
							link: URL,
							idEndereco: idEndereco,
							idProf: dados.idProf,
							idServico: id
						})

						if (resp.codigo != 200)
							try {
								window.AndroidInterface.showAndroidVersion(resp.mensagem ?? "não foi possível salvar a imagem, tente novamente mais tarde.")
							} catch {
								alert(resp.mensagem ?? "não foi possível salvar a imagem, tente novamente mais tarde.")
							}
					}}
				/>
			</BoundaryProtocol>
		</Backdrop>
		{/* <Protocolo open={camera} openCamera={openCamera} idEndereco={idEndereco} setProtocoloLoading={setProtocoloLoading} id={id} load={setCarregando} /> */}
	</>
}

function ProtocolContainer({ opcao, handleClose, reload, handleDB, onObsolete }) {
	const [open, setOpen] = useState(false)
	const [openGallery, setOpenGallery] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")

	async function handleCamera() {
		if (await CheckPermission.checkCamera()) {
			if (window.AndroidInterface?.recoverVersion() >= 102.26) {
				setOpen(true)
			} else {
				onObsolete()
			}
		} else try {
			window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera)
		} catch {
			print("É necessário ativar a permissão de câmera neste aplicativo!")
		}
	}

	async function handleGallery() {
		setOpenGallery(true)
	}

	return <Card className="m-3 p-3 position-relative">
		<IconButton disabled={open || openGallery} className="position-absolute end-0 top-0" onClick={handleClose}>
			<Close />
		</IconButton>
		<h4 className="my-3">Qual forma deseja buscar sua imagem de protocolo?</h4>
		<div className="d-flex gap-3">
			{opcao != "G" && window.AndroidInterface && <Button
				variant="contained"
				className="mt-3"
				disabled={open || openGallery}
				startIcon={<CameraAlt />}
				onClick={handleCamera}
				fullWidth
			>
				Câmera
			</Button>}
			{opcao != "C" && <Button
				variant="contained"
				className="mt-3"
				disabled={open || openGallery}
				onClick={handleGallery}
				startIcon={<Collections />}
				fullWidth
			>
				Galeria
			</Button>}
		</div>

		{open && <ProtocolCamera
			onFinish={async (URL) => {
				setErrorMsg("")
				await handleDB(URL)
				setOpen(false)
				reload()
			}}
			onError={msg => {
				setErrorMsg(msg)
				setOpen(false)
			}}
		/>}

		{openGallery && <ProtocolGallery
			onFinish={async (URL) => {
				setErrorMsg("")
				await handleDB(URL)
				setOpenGallery(false)
				reload()
			}}
			onError={msg => {
				setErrorMsg(msg)
				setOpenGallery(false)
			}}
		/>}
		{!open && !openGallery && errorMsg && errorMsg.length > 0 && <Alert severity="error" className="mt-3" icon={false}>
			{errorMsg}
		</Alert>}
	</Card>
}

function ProtocolCamera({ onFinish, onError }) {
	useCamera(onFinish, onError)

	return <div className="text-center mt-3">
		<CircularProgress />
	</div>
}

function ProtocolGallery({ onFinish, onError }) {
	useGallery(onFinish, onError)

	return <div className="text-center mt-3">
		<CircularProgress />
	</div>
}

function ModalIfood({ url, back, state }) {
	return <Backdrop style={{
		zIndex: 1000
	}} in={url != ""}>
		<div className="position-relative" style={{
			background: "white",
			padding: "0 1rem"
		}}>
			<Button
				startIcon={<Undo />}
				onClick={back}
				fullWidth
			>
				Voltar
			</Button>

			<CardEndereco
				id={state?.idAtivo}
				endereco={state?.endereco}
				icone
				observacao={state?.observacao}
			/>
			<div id="ifood" style={{
				height: "calc(100vh - 13rem)",
				overflow: "auto"
			}}>
				<iframe src={url} style={{
					width: "100%",
					background: "white",
					height: "100vh",
					overflow: "hidden",
					scrollbarWidth: "10px"
				}} />
			</div>
		</div>
	</Backdrop>
}

class BoundaryProtocol extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
		// Log error to an external service if needed
		console.error('Error caught by BoundaryProtocol:', error, errorInfo);
	}

	render() {
		const { hasError, error } = this.state;

		if (hasError) {
			return (
				<div
					style={{
						padding: '20px',
						border: '2px solid red',
						backgroundColor: '#ffe5e5',
						borderRadius: '5px',
						fontFamily: 'Arial, sans-serif',
						textAlign: 'center',
					}}
				>
					<h1>Something went wrong!</h1>
					<p>{error?.message || 'An unexpected error occurred.'}</p>
					<p style={{ fontSize: 'smaller', color: '#555' }}>
						Please try refreshing the page or contact support if the issue persists.
					</p>
				</div>
			);
		}

		return this.props.children;
	}
}

function EstruturaCodigo({ label, info, uri, idEndereco, metodo, reload }) {
	const { id } = useParams()
	const { dados } = useContext(ContextoGeral)

	const [anchorEl, setAnchorEl] = useState(null)
	const [codigo, setCodigo] = useState("")

	const [codErr, setCodErr] = useState(false)
	const [codMsg, setCodMsg] = useState('')
	const [codLoading, setCodLoading] = useState(false)

	const infoPop = Boolean(anchorEl)

	async function handleCodigo(e) {
		if (e.target.value.length < 5)
			setCodigo(e.target.value)
	}

	return <>
		<div className="mt-3 d-flex gap-3">
			<div style={{ width: "calc(100% - 4rem)" }}>
				<div className="position-relative w-100" ref={anchorEl}>
					<Campo
						label={label}
						value={codigo}
						tipo="number"
						onChange={handleCodigo}
						danger={codErr}
					/>
					<IconButton onClick={e => setAnchorEl(e.target ?? null)} className="position-absolute" sx={{ right: "6px", top: "50%", transform: "translate(0,-50%)" }}>
						<MdInfo />
					</IconButton>
					<Popover open={infoPop} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}>
						<Typography sx={{ p: 2, lineHeight: 1 }}>
							{info
								? info
								: "Digite aqui o código de 4 dígitos associados ao endereço."}
						</Typography>
					</Popover>
				</div>
			</div>
			<div className="d-flex align-items-center">
				<IconButton disabled={codLoading} onClick={async () => {
					setCodMsg("")
					setCodLoading(true)

					let location = {
						latitude: 0,
						longitude: 0
					}

					if (window?.AndroidInterface) location = JSON.parse(await window.AndroidInterface.getLocation())

					if (window.isLocalHost)
						location = { latitude: -21, longitude: -44 }

					await ServicoAPI.informarCodigo(uri, metodo, dados, {
						idProf: dados.idProf,
						idServico: id,
						idEndereco: idEndereco,
						codigoDigitado: codigo,
						code: codigo,
						la: location.latitude,
						lo: location.longitude
					}).then(r => {
						let err = !r?.resultado


						if (r.mensagem?.status)
							err = !r.mensagem?.status

						if (!err) {
							print("Sucesso!")

							setTimeout(() => {
								reload()
							}, 2000)
						}

						if (typeof r?.mensagem == "string")
							setCodMsg(r.mensagem)
						else setCodMsg(r?.mensagem?.msg ?? "Falha ao confirmar código!")
						setCodErr(err)
					}).catch(r => {
						setCodMsg("Falha ao se conectar")
					}).finally(() => setCodLoading(false))
				}}>
					<MdSend />
				</IconButton>
			</div>
		</div>
		<Collapse in={codLoading}>
			<div className="mt-3 text-center">
				<CircularProgress />
			</div>
		</Collapse>
		<Collapse in={codMsg != "" && !codLoading}>
			<Card className={"mt-3 p-2 text-center text-white" + (codErr ? " bg-danger" : " bg-success")}>
				{codMsg}
			</Card>
		</Collapse>
	</>
}