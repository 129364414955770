import axios from "axios"
import API from "./API.jsx"

import mensagemApp from './config/MensagemApp.js'

class ServicoAPI {
	static async qtdServico(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/qtdeServicos`,
			params: {
				idProf: dados.idProf
			},
			method: "GET",
			metodo: "GET",
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarServicos(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: '/servicoExecucao',
			params: {
				idProf: dados.idProf
			},
			method: 'GET',
			metodo: 'GET',
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, erro: mensagemApp.msgErroApi }
		})
	}

	static async buscarServicosAcertar(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: '/servicoAcertar',
			params: {
				idProf: dados.idProf
			},
			method: 'GET',
			metodo: 'GET',
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, erro: mensagemApp.msgErroApi }
		})
	}

	static async buscarQuantAcertar(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: '/servicoAcertarQuant',
			params: {
				idProf: dados.idProf
			},
			method: 'GET',
			metodo: 'GET',
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, erro: mensagemApp.msgErroApi }
		})
	}

	static async buscarServico(id, dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/servicoExecucao?idProf=${dados.idProf}&idServico=${id}`,
			method: 'GET',
			metodo: 'GET',
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, erro: mensagemApp.msgErroApi }
		})
	}

	static async statusFinalizar(dados, idServico, idEndereco, tipoVeiculo, location) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/statusFinalizar?idProf=${dados.idProf}&idServico=${idServico}&idEndereco=${idEndereco}&tipoVeiculo=${tipoVeiculo}&la=${location.latitude}&lo=${location.longitude}`,
			method: 'GET',
			metodo: 'GET',
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res }).catch(rej => { return rej })
	}

	static async marcarChegada(dados, data, location) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: '/chegueiEndereco',
			method: 'PUT',
			metodo: 'PUT',
			cabecalho: {
				token: dados.token
			},
			data: {
				idProf: dados.idProf,
				idServico: data.idServico,
				idEndereco: data.idEndereco,
				la: location.latitude,
				lo: location.longitude
			}
		}).then(res => { return res }).catch(rej => { return rej })
	}

	static async finalizarEndereco(dados, data) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/finalizarEndereco`,
			method: 'PUT',
			metodo: 'PUT',
			cabecalho: {
				token: dados.token
			},
			data: {
				idProf: dados.idProf,
				idServico: data.idServico,
				idEndereco: data.idEndereco,
				la: data.la,
				lo: data.lo,
				observacao: data.observacao,
				motivo: data.motivo
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarCotacoes(dados, local) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/cotacoes`,
			method: 'GET',
			metodo: 'GET',
			params: {
				idProf: dados.idProf,
				la: local.latitude,
				lo: local.longitude
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async responderCotacao(dados, data, local) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/responderCotacao`,
			method: 'PUT',
			metodo: 'PUT',
			data: {
				...data,
				la: local.latitude,
				lo: local.longitude
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarPuxarServicos(dados, local) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/puxarServicos`,
			method: 'GET',
			metodo: 'GET',
			params: {
				idProf: dados.idProf,
				la: local.latitude,
				lo: local.longitude
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async responderPuxarServico(dados, idServico) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/puxarServico`,
			method: 'PUT',
			metodo: 'PUT',
			data: {
				idProf: dados.idProf,
				idServico: idServico
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async enviarAssinatura(dados, idServico, idEndereco, nome, doc, tipoDoc, imagem) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/salvarDadosFinalizar`,
			method: 'POST',
			metodo: 'POST',
			data: {
				idProf: dados.idProf,
				idServico: idServico,
				idEndereco: idEndereco,
				identificador: 'A',
				nome: nome,
				doc: doc,
				tipo: tipoDoc,
				imagem: imagem
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res }).catch(rej => { return rej })
	}

	static async validarCPF(dados, doc, tipoDoc) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/validarDocs`,
			method: 'POST',
			metodo: 'POST',
			data: {
				tipo: tipoDoc,
				doc: doc,
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async cancelarServico(dados, id, opt, label) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/cancServApp",
			method: "POST",
			metodo: "POST",
			data: {
				idServico: id,
				opCancelamento: opt,
				txtCancelamento: label,
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarConfigTelaCotacao(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/telaCotacoes",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async salvarImagemCDN(dados, img) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/salvarImagemCDN",
			method: "POST",
			metodo: "POST",
			data: {
				idProf: dados.idProf,
				imagem: img
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async salvarFotoProtocolo(dados, body) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/salvarFotoProtocolo",
			method: "POST",
			metodo: "POST",
			data: body,
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async informarProxEntrega(dados, body) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/informarProximaEntrega",
			method: "PUT",
			metodo: "PUT",
			data: body,
			cabecalho: {
				token: dados.token
			}
		})
	}

	static async acertar(dados, idServico) {
		return await API.post("/appProfissional/accessOutside", {
			dominio: dados.dominio,
			method: 'POST',
			metodo: 'POST',
			url: `https://${dados.dominio}/mobile/v50/informarAcertou.php`,
			data: {
				tokenAut: dados.token,
				idServico: idServico,
				idProf: dados.idProf,
				idMotoboy: dados.idProf
			},
			headers: {
				"Content-Type": "application/json"
			},
		}).then(res => res.data).catch(rej => rej)
	}

	static async cancelarServicoAcerto(dados, idServico, opt) {
		return await API.post("/appProfissional/accessOutside", {
			dominio: dados.dominio,
			method: 'POST',
			metodo: 'POST',
			url: `https://${dados.dominio}/mobile/v50/recebeCancelamentoApp.php`,
			data: {
				tokenAut: dados.token,
				idServico: idServico,
				idProf: dados.idProf,
				idMotoboy: dados.idProf,
				opCancelamento: opt,
				txtCancelamento: opt == "RS"
					? "Reabrir o serviço para outros fazerem"
					: opt == "CS"
						? "Cancelar de vez o serviço"
						: "Deixar o serviço sem profissional",
				alertaPainel: "S"
			},
			headers: {
				"Content-Type": "application/json"
			},
		}).then(res => res.data).catch(rej => rej)
	}

	static confirmarColeta = async (dados, data) => API.post("/appProfissional/acessar", {
		dominio: dados.dominio,
		method: 'POST',
		metodo: 'POST',
		rota: "/confirmarColeta",
		data: data,
		cabecalho: { token: dados.token }
	}).then(res => res.data).catch(rej => rej)

	static limparEndereco = async (dados, data) => API.post("/appProfissional/acessar", {
		dominio: dados.dominio,
		method: 'PUT',
		metodo: 'PUT',
		rota: "/limparEndereco",
		data: data,
		cabecalho: { token: dados.token }
	}).then(res => res.data).catch(rej => rej)

	static informarCodigo = async (uri, metodo, dados, data) => API.post("/appProfissional/acessar", {
		dominio: dados.dominio,
		method: metodo,
		metodo: metodo,
		rota: uri,
		data: data,
		cabecalho: { token: dados.token }
	}).then(res => res.data).catch(rej => rej)
}

export default ServicoAPI