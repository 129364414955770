import { useState, useEffect, useContext, useCallback } from 'react';
import { ContextoGeral } from "./../../../contextos/ContextoGeral";

const useMessageCount = (contar, location) => {

    const [dadosNotificacoes, setDadosNotificacoes] = useState([])

    if (location?.pathname != '/login') {

        const { dados, websocket } = useContext(ContextoGeral);

        useEffect(() => {

            if (contar && websocket) {

                websocket.emit("get-message-count", { userId: dados?.idProf }, (arrayMensagens) => {

                    console.log('get-message-count', arrayMensagens);

                    // Montando o novo objeto com chave e valor desejados
                    const novoObjeto = Object.fromEntries(
                        Object.entries(arrayMensagens).map(([chave, valor]) => [chave, valor.count])
                    );

                    const dadosServico = Object.fromEntries(
                        Object.entries(arrayMensagens).map(([chave, valor]) => [chave, valor.dadosServico])
                    );

                    setDadosNotificacoes({
                        salas: novoObjeto,
                        total: Object.values(novoObjeto).reduce((acc, count) => acc + (count || 0), 0),
                        dadosServico: dadosServico
                    });
                });


                websocket.on("update-count", ({ salas, totalMensagens }) => {
                    // Montando o novo objeto com chave e valor desejados
                    const novoObjeto = Object.fromEntries(
                        Object.entries(salas).map(([chave, valor]) => [chave, valor.count])
                    );

                    const dadosServico = Object.fromEntries(
                        Object.entries(salas).map(([chave, valor]) => [chave, valor.dadosServico])
                    );

                    setDadosNotificacoes({
                        salas: novoObjeto,
                        total: Object.values(novoObjeto).reduce((acc, count) => acc + (count || 0), 0),
                        dadosServico: dadosServico
                    });
                });

            }
        }, [contar, websocket, dados]);

    }
    return { dadosNotificacoes };
};

export default useMessageCount;
