import { Alert, Card, Pagination, Zoom } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { Campo } from "../../../componentes/form/campo";
import CardServico from "../../../componentes/servicos/cardServico";
import { Spinner } from "../../../componentes/Spinner";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import AppAPI from "../../../servicos/AppAPI";
import CardEndereco from "../../../componentes/form/CardEndereco";
import { MdClose, MdInfo } from "react-icons/md";

export default function RelatorioConcluidos({ config }) {
    const [listaConcluidos, setListaConcluidos] = useState('')
    const [totais, setTotais] = useState({})

    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [forma, setForma] = useState(0)

    const [intervalo, setIntervalo] = useState(false)
    const [servicoSelecionado, setServicoSelecionado] = useState(null)

    const [valores, setValores] = useState({
        idCliente: 'todos',
        periodo: 'mesAtual',
        formaPgto: 'todos'
    })

    const { dados } = useContext(ContextoGeral)

    const handleConfigBusca = async (e) => {
        let jsonValores = { ...valores }

        if (e.target.id == 'idCliente') {
            jsonValores.idCliente = e.target.value
        } else if (e.target.id == 'periodo') {
            jsonValores.periodo = e.target.value

            if (e.target.value == 'intervalo') {
                setIntervalo(true)
            } else {
                setIntervalo(false)
            }
        } else if (e.target.id == 'dataInicial') {
            jsonValores.inicio = e.target.value
        } else if (e.target.id == 'dataFinal') {
            jsonValores.fim = e.target.value
        } else if (e.target.id == 'formaPgto') {
            jsonValores.formaPgto = e.target.value
        }

        setValores(jsonValores)
    }

    async function buscarConcluidos() {
        setTotais({})
        setListaConcluidos(<Spinner />)
        let key = 0

        let retorno = await AppAPI.buscarRelatorioConcluidos(dados, valores, page)

        if (retorno.resultado && retorno.dados != '') {
            if (retorno.dados.formaBusca) setForma(retorno.dados.formaBusca)
            if (Array.isArray(retorno.dados.servicos)) {
                setTotalPages(parseInt(retorno.dados.servicosTotal.concluidos / 20) + 1)

                let lista = retorno.dados.servicos.map(concluido => {
                    key++
                    return <Zoom
                        key={`concluido-${key}`}
                        className="mt-3"
                        in={true}
                    >
                        <div>
                            <CardServico servico={concluido} setModal={setServicoSelecionado} obj={concluido} mostrarNomes />
                        </div>
                    </Zoom>
                })
                setListaConcluidos(lista)
                setTotais(retorno.dados.servicosTotal)
            }
        } else {
            setListaConcluidos(
                <Alert severity="error" className="text-center pb-3">
                    {retorno.mensagem}
                </Alert>
            )
        }
    }

    useEffect(() => {
        if (page > 0) buscarConcluidos()
    }, [page])

    return <>
        <div className="mb-3">
            <div className="my-3">
                <div>
                    <Campo
                        id="idCliente"
                        tipo="select"
                        label={config.filtros.servicosConcluidos.cliente.label}
                        onChange={handleConfigBusca}
                        options={config.filtros.servicosConcluidos.cliente.opcoes}
                        obrigatorio={config.filtros.servicosConcluidos.cliente.obrigatorio}
                        value={valores.idCliente}
                    />
                </div>
                <div className="mt-3">
                    <Campo
                        id="periodo"
                        tipo="select"
                        label={config.filtros.servicosConcluidos.periodo.label}
                        onChange={handleConfigBusca}
                        options={config.filtros.servicosConcluidos.periodo.opcoes}
                        obrigatorio={config.filtros.servicosConcluidos.periodo.obrigatorio}
                        value={valores.periodo}
                    />
                </div>
                {intervalo && <Zoom in={true} style={{ transitionDelay: 0 }}>
                    <div>
                        <div className="mt-3">
                            <Campo
                                id="dataInicial"
                                tipo="date"
                                label="Data Inicial"
                                onChange={handleConfigBusca}
                                obrigatorio
                            />
                        </div>
                        <div className="mt-3">
                            <Campo
                                id="dataFinal"
                                tipo="date"
                                label="Data Final"
                                onChange={handleConfigBusca}
                                obrigatorio
                            />
                        </div>
                    </div>
                </Zoom>}
                <div className="mt-3">
                    <Campo
                        id="formaPgto"
                        tipo="select"
                        label={config.filtros.servicosConcluidos.formasPgto.label}
                        onChange={handleConfigBusca}
                        options={config.filtros.servicosConcluidos.formasPgto.opcoes}
                        obrigatorio={config.filtros.servicosConcluidos.formasPgto.obrigatorio}
                        value={valores.formaPgto}
                    />
                </div>

                <Row className="g-0 mt-3">
                    <button onClick={() => {
                        setPage(1)
                        buscarConcluidos()
                    }
                    }>Buscar</button>
                </Row>

            </div>
            {Object.keys(totais).length > 0 && <Zoom
                className="mt-3"
                in={true}
            >
                <div>
                    {totalPages > 1 && <div className="d-flex justify-content-center mb-3">
                        <Pagination
                            sx={{
                                backgroundColor: "transparent",
                                filter: "drop-shadow(none)"
                            }}
                            page={page}
                            count={totalPages}
                            onChange={(e, val) => {
                                setPage(val)
                            }} />
                    </div>}
                    {forma && <Card className="bg-info text-light p-3 mb-3">
                        <div className="d-flex justify-content-between">
                            <MdInfo size={55} className="me-3" />
                            {forma}
                        </div>
                    </Card>}

                    <Card className="bg-success text-light p-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                Serviços concluídos:
                            </div>
                            <div>
                                {totais.concluidos}
                            </div>
                        </div>
                        {totais.kmTotal && <div className="d-flex justify-content-between">
                            <div>
                                Total KM:
                            </div>
                            <div>
                                {totais.kmTotal}
                            </div>
                        </div>}
                        <div className="d-flex justify-content-between">
                            <div>
                                Total Valor:
                            </div>
                            <div>
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                }).format(totais.valores)}
                            </div>
                        </div>
                    </Card>
                </div>
            </Zoom>}

            <div className="mt-3 pb-3">
                {listaConcluidos}
            </div>
        </div>

        <Modal show={servicoSelecionado != null} onHide={() => setServicoSelecionado(null)} centered>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <h3 className="fs-5 fw-bold m-0">
                        #{servicoSelecionado?.["s.id"]}
                    </h3>

                    <MdClose size={32} onClick={() => setServicoSelecionado(null)} />
                </div>
                <p className="m-0 opacity-50">
                    Lista de endereços
                </p>

                {servicoSelecionado?.enderecos && Array.isArray(servicoSelecionado?.enderecos)
                    ? servicoSelecionado?.enderecos.map(endereco => (
                        <CardEndereco
                            key={endereco["e.id"]}
                            id={endereco["es.ponto"]}
                            endereco={endereco["e.rua"]}
                            observacao={endereco['e.observacao'] ?? endereco['e.responsavel']}
                            icone
                        />
                    )) : null}
            </Modal.Body>
        </Modal>
    </>
}