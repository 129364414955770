import { useContext, useEffect, useState } from "react"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { ContextoGeral } from "../../../contextos/ContextoGeral"

import { Spinner } from "../../../componentes/Spinner"

import ProfissionalAPI from "../../../servicos/ProfissionalAPI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Alert, Backdrop, Button, Zoom } from "@mui/material"
import { Campo } from "../../../componentes/form/campo"
import MensagemApp from "../../../servicos/config/MensagemApp"
import { ProtocolContainer } from "../../../componentes/gerais/CamGalleryBlock"

export default function DadosUsuario() {
	const [menu, setMenu] = useState(false)

	const [carregando, setCarregando] = useState(true)
	const [completo, setCompleto] = useState(false)
	const [open, setOpen] = useState(false)
	const [msg, setMsg] = useState("")

	const [dadosAdicionais, setDadosAdicionais] = useState({})
	const [codigo, setCodigo] = useState(0)
	const [ver, setVer] = useState("")
	const [identificador, setIdentificador] = useState("")

	const [formCampos, setFormCampos] = useState([])

	const { dados } = useContext(ContextoGeral)

	const valido = (codigo == 200 || codigo == 0) ? true : false

	useEffect(() => {
		if (window.AndroidInterface)
			setVer(AndroidInterface?.recoverVersion())
	}, [])

	useEffect(() => {
		async function buscarDados() {
			let retorno = await ProfissionalAPI.buscarDadosAdicionais(dados)
			console.log(retorno)

			setCodigo(retorno?.codigo)

			if (retorno.resultado) {

				if (retorno.dados != '') {
					let arr = []
					Object.values(retorno.dados.campos).forEach((item) => {
						arr.push({
							name: item.coluna,
							value: ""
						})
					})

					setFormCampos(arr)
					setDadosAdicionais(retorno.dados)
				} else {
					setCompleto(true)
					setMsg(retorno.mensagem)
					setDadosAdicionais({})
				}
			} else {
				setMsg(retorno?.mensagem)
				if (!!window.AndroidInterface) {
					window.AndroidInterface.showAndroidVersion(retorno?.mensagem)
				}

				setDadosAdicionais({})
			}
		}

		buscarDados()
	}, [carregando])

	useEffect(() => {
		if (Object.keys(dadosAdicionais).includes('docEnviadoCompleto') &&
			dadosAdicionais.docEnviadoCompleto) {
			setCompleto(true)
		}

		setCarregando(false)
	}, [dadosAdicionais])

	const handleCampos = (e) => {
		let prev = formCampos
		let arr = []

		prev.forEach(item => {
			if (item.name == e.target.id) {
				arr.push({
					name: item.name,
					value: e.target.value
				})
			} else {
				arr.push(item)
			}
		})

		let json = dadosAdicionais
		delete json.campos[e.target.id].erro

		setDadosAdicionais(json)
		setFormCampos(arr)
	}

	const enviarCampos = async () => {

		setCarregando(true)

		let campos = {}
		formCampos.forEach((item => {
			campos[item.name] = item.value
		}))
		let retorno = await ProfissionalAPI.enviarDadosUsuario(dados, campos)
		if (retorno?.resultado) {
			if (!!window.AndroidInterface) {
				console.log("passou")
				window.AndroidInterface?.showAndroidVersion(retorno?.mensagem)
			}

			setCarregando(false)
		} else {
			if (!!window.AndroidInterface) {
				window.AndroidInterface.showAndroidVersion(retorno?.mensagem)
			}

			if (retorno.aux != '') {
				if (retorno.aux in campos) {
					let json = dadosAdicionais

					formCampos.forEach((item, i) => {
						if (item.name == retorno.aux) {
							json.campos[retorno.aux] = { ...json.campos[retorno.aux], erro: retorno.mensagem }
						}
					})

					setDadosAdicionais(prev => {
						return { ...prev, ...json }
					})
				}
			}

			setCarregando(false)
		}
	}

	const handleArquivo = (e) => {
		e.preventDefault()

		if (!!window.AndroidInterface) {
			window.finalizouEnvio = (res) => {
				let retorno = JSON.parse(res)
				alert(JSON.stringify(retorno, null, 3))
				e.target.blur()
				setCarregando(true)

				if (retorno?.resultado) {
					if (!!window.AndroidInterface) {
						window.AndroidInterface.showAndroidVersion(retorno?.mensagem)
					}

				} else {
					if (!!window.AndroidInterface) {
						window.AndroidInterface.showAndroidVersion(retorno?.mensagem ?? MensagemApp.timeout)
					}
				}
			}

			window.AndroidInterface.ativaCamera('DC', parseInt(dados.idProf), 0, 0, 'FP', e.target.id, 'uploadImg', dados.token)
		}
	}

	return <>
		<div className="h-100">
			<TopoMenu voltar="/app/home" titulo="DADOS ADICIONAIS" setShow={setMenu} />
			<div className="mx-2" style={{ height: 'calc(100% - 3rem)' }}>
				{carregando && dadosAdicionais.length > 0
					? <Spinner />
					: valido
						? completo
							? <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
								<Alert security="success" className="mt-3">
									Seus dados já foram enviados com sucesso!
								</Alert>
							</div>
							: <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
								<div>
									<h3 className="text-center">{dadosAdicionais.tituloPagina}</h3>
									<Tabs
										defaultActiveKey={!!dadosAdicionais.campos && Object.keys(dadosAdicionais.campos).length > 0
											? 'campos'
											: 'arquivos'}
										className="my-3"
										variant="pills"
										justify
									>
										{Array.isArray(formCampos)
											? <Tab
												eventKey="campos"
												title={dadosAdicionais.labelBtnTexto ?? "Dados"}
											>
												{dadosAdicionais.campos && Object.values(dadosAdicionais.campos).map((e, i) => {
													if (e.enviado) return

													return <div key={e.coluna != "" ? e.coluna : e.label}>
														<Zoom
															className="mt-3"
															in={true}
															style={{ transitionDelay: i * (1000 / (Object.keys(dadosAdicionais.campos).length) ?? 10) }}
														>
															<div className="mt-3">
																<Campo
																	id={e.coluna ?? e.label}
																	label={e.label}
																	tipo={e.tipoCampo}
																	options={e?.opcoes ?? null}
																	danger={!!e.erro ? 1 : 0}
																	value={formCampos[i].value}
																	onChange={handleCampos}
																/>
																<div className="text-danger">
																	{e?.erro}
																</div>
															</div>
														</Zoom>
													</div>
												})}
												<Button className="p-2 my-3" onClick={enviarCampos} variant="contained" fullWidth>
													Enviar
												</Button>
											</Tab>
											: ''}
										{dadosAdicionais.arquivos && Object.keys(dadosAdicionais.arquivos).length > 0
											&& <Tab
												eventKey="arquivos"
												title={dadosAdicionais.labelBtnArquivo ?? "Arquivos"}
											>
												{Object.values(dadosAdicionais.arquivos).map((e, i) => {

													return <div key={e?.coluna ?? e.identificador}>
														<Zoom
															className="mt-3"
															in={true}
															style={{
																transitionDelay: Object.keys(dadosAdicionais.campos).length > 0
																	? i * (1000 / (Object.keys(dadosAdicionais.campos).length ?? 10))
																	: 0
															}}
														>
															<div className="mt-3">
																{ver >= 102.26
																	? <button onClick={() => {
																		setIdentificador(e.coluna ?? e.identificador)
																		setOpen(true)
																	}} className="w-100">
																		{e.label}
																	</button>
																	: <Campo
																		id={e.coluna ?? e.identificador}
																		label={e.label}
																		tipo={e.tipoCampo}
																		tabindex="-1"
																		onChange={handleArquivo}
																	/>}
															</div>
														</Zoom>
													</div>
												})}
											</Tab>}
									</Tabs>
								</div>
							</div>
						: <div>
							<Alert severity="error" className="mt-3">
								{msg}
							</Alert>
						</div>}
			</div>
		</div>
		<Backdrop open={open}>
			<ProtocolContainer
				handleClose={() => setOpen(false)}
				handleDB={async URL => ProfissionalAPI.salvarDadosAdicionais(dados, {
					identificador: identificador,
					idProf: dados.idProf,
					link: URL
				}).then(res => {
					if (res.sucesso) {
						try {
							AndroidInterface.showAndroidVersion(res.mensagem)
						} catch {
							alert(res.mensagem)
						}
						setOpen(false)
						setCarregando(true)
					}
				}).catch(rej => alert(JSON.stringify(rej, null, 3)))}
				onObsolete={() => {
					//
				}}
				opcao=""
				reload={() => {
					setCarregando(true)
					setOpen(false)
				}}
			/>
		</Backdrop>
		<MenuLateral show={menu} setShow={setMenu} ativo={4} />
	</>
}