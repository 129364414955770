import { useLocation, Link, useNavigate } from "react-router-dom"
import { MdArrowBackIos, MdMenu } from 'react-icons/md'

import styled from "styled-components"
import { useContext, useState, useEffect } from "react"

import { ContextoGeral } from './../../contextos/ContextoGeral';

const Header = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 500;
`

const BtnVoltar = styled.div`
    position: absolute;
    left: 1rem;
    z-index: 100;
`

const BtnMenu = styled.div`
    position: absolute;
    right: 1rem;
    z-index: 100;
`

const Titulo = styled.div`
    font-size: 0.85rem;
`

export default function TopoMenu(props) {
    const { dados } = useContext(ContextoGeral)

    const navegar = useNavigate()

    function navegacao() {
        navegar(props.voltar, {
            state: props.state
        })
    }

    const location = useLocation()

    // const exibirChat = location?.pathname != '/app/chat' && location?.pathname != '/login' && location?.pathname != '/app/servicos' && location?.pathname != `/app/servicos/${location?.state?.idServico}`;

    // const { dadosNotificacoes } = useMessageCount(true, location);
    // const [mostrarNotificacoes, setMostrarNotificacoes] = useState('none');
    // const [notifCentral, setNotifCentral] = useState(0);

    // Calcula a quantidade de mensagens da sala 'Central' uma vez
    // useEffect(() => {

    //     const chaveSala = `P${dados.idProf}`

    //     if (dadosNotificacoes?.salas) {

    //         setNotifCentral(dadosNotificacoes?.salas[chaveSala]);
    //     }

    // }, [dadosNotificacoes]);

    // function abrirChat() {

    //     if (dadosNotificacoes.salas && Object.keys(dadosNotificacoes.salas).length > 0) {
    //         if (mostrarNotificacoes == 'none') {
    //             setMostrarNotificacoes('block')
    //         } else {
    //             setMostrarNotificacoes('none')
    //         }
    //     } else {

    //         navegar('/app/chat', {
    //             state: {
    //                 conversa: 'central',
    //                 notificacao: dadosNotificacoes
    //             }
    //         })
    //     }
    // }

    // function selecionarSala(nomeSala, id, sala = '') {

    //     if (nomeSala == 'central') {
    //         navegar('/app/chat', {
    //             state: {
    //                 conversa: 'central',
    //                 notificacao: dadosNotificacoes
    //             }
    //         })
    //     } else {

    //         navegar(`/app/chat`, {
    //             state: {
    //                 conversa: 'cliente',
    //                 idServico: dadosNotificacoes.dadosServico[sala].idServico,
    //                 idSolicitante: dadosNotificacoes.dadosServico[sala].idSolicitante,
    //                 idCentroCusto: dadosNotificacoes.dadosServico[sala].idCentroCusto,
    //                 status: dadosNotificacoes.dadosServico[sala].status,
    //                 notificacao: dadosNotificacoes
    //             }
    //         })
    //     }
    // }

    return (
        <Header id="header">
            <BtnVoltar>
                <MdArrowBackIos
                    size={28}
                    className="float-right"
                    onClick={() => { navegacao() }}
                />
            </BtnVoltar>
            <Titulo>{props.titulo}</Titulo>
            {props.setShow ? (
                <BtnMenu>
                    <MdMenu
                        size={28}
                        className="float-right"
                        onClick={() => { props.setShow(true) }}
                    />
                </BtnMenu>
            ) : ('')}

            <div
                style={{
                    position: 'fixed',
                    bottom: '22vh', // distância do rodapé
                    left: '36vh',
                    zIndex: -1, // para garantir que o botão fique sobre outros elementos
                    height: '0vh',
                    width: '100vh',
                    display: "none"
                    // display: `${mostrarNotificacoes}`
                }}
            >
                <div
                    style={{
                        backgroundColor: '#fff',
                        width: '32vh',
                        height: '20vh',
                        position: 'absolute',
                        top: '-111px', // Posiciona o quadrado acima do botão
                        left: '-230px', // Centraliza horizontalmente sobre o botão
                        borderRadius: '8px', // Opcional: ligeiramente arredondado
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Sombra leve para destaque
                        padding: '2vh',
                        overflowY: 'auto', // Permite rolagem vertical
                    }}
                >

                    {/* <div key={'central'}
                        style={{ marginBottom: '3vh' }}
                        onClick={() => selecionarSala('central', 0)}>
                        Central - <strong>{notifCentral} mensagens</strong>
                    </div> */}

                    {/* {dadosNotificacoes.salas && Object.keys(dadosNotificacoes.salas).length > 0 ? (
                        Object.entries(dadosNotificacoes.salas).map(([sala, quantidade]) => {

                            if (quantidade && sala) {
                                const identSala = sala.match(/[A-Za-z]+/g)?.[0] || '';
                                const numeroSala = sala.match(/\d+/g)?.[0] || '';
                                let nomeSala = '';

                                if (identSala === 'E') {
                                    nomeSala = `Serviço: ${numeroSala}`;
                                }

                                if (nomeSala) {
                                    return (
                                        <div
                                            key={sala}
                                            style={{ marginBottom: '3vh' }}
                                            onClick={() => selecionarSala(nomeSala, numeroSala, sala)}
                                        >
                                            {nomeSala} - <strong>{quantidade} mensagens</strong>
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })
                    ) : (
                        ''
                    )} */}

                </div>
            </div>

            {/* {exibirChat ?
                <div
                    style={{
                        position: 'fixed',
                        bottom: '5vh', // distância do rodapé
                        right: '9px', // distância da direita
                        zIndex: 1000,  // para garantir que o botão fique sobre outros elementos
                    }}
                >
                    <Button
                        variant='contained'
                        style={{
                            backgroundColor: '#100940',
                            borderRadius: '50%',
                            width: '51px',
                            height: '54px',
                        }}
                        onClick={abrirChat} // Melhor simplificar o código aqui
                    >
                        <ChatIcon style={{ color: 'white' }} />
                    </Button>

                    {dadosNotificacoes?.total > 0 && (
                        <span
                            style={{
                                marginLeft: '-7px',  // Espaço entre o texto e o círculo
                                backgroundColor: 'rgb(255 0 0)',
                                borderRadius: '50%',
                                width: '28px',
                                height: '28px',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                bottom: '38px',
                                left: '47px',
                                position: 'absolute',
                            }}
                        >
                            {dadosNotificacoes?.total}
                        </span>
                    )}
                </div>
                : ''} */}
                
        </Header>
    )
}